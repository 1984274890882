import axios from "axios";
import { toast } from "react-toastify";




/*export const config = {
    v2Url: "http://localhost:5001/api/v2",
    v1Url: "http://localhost:5001/api/",
    bradmaxUrl: "https://bradmax.com/client/embed-player/b7cfcffb634dbf3efe089894f7e2024e4981871e_13355?mediaUrl=",
    recaptchaKey: "6Lf00zIlAAAAAJhzcbbQJ1_2JnLjlMc-5yoAfCAy",
    googleClientId: "229646675402-5bri8t4a0d6nhru51ujhntpdussmo1gk.apps.googleusercontent.com",
    facebookAppId: process.env.NODE_ENV === "development" ? "1062690044893442" : "1055633015885252",
    googlePlayUrl:"https://play.google.com/store/apps/details?id=com.tritux.samifehri"
}*/

export const config = {
    v2Url: "https://samifehri.tn/api/v2",
    v1Url: "https://samifehri.tn/api/",
    bradmaxUrl: "https://bradmax.com/client/embed-player/b7cfcffb634dbf3efe089894f7e2024e4981871e_13355?mediaUrl=",
    recaptchaKey: "6Lf00zIlAAAAAJhzcbbQJ1_2JnLjlMc-5yoAfCAy",
    googleClientId: "229646675402-5bri8t4a0d6nhru51ujhntpdussmo1gk.apps.googleusercontent.com",
    facebookAppId: process.env.NODE_ENV === "development" ? "1062690044893442" : "1055633015885252",
    googlePlayUrl:"https://play.google.com/store/apps/details?id=com.tritux.samifehri",
    appleStoreUrl:"https://apps.apple.com/tn/app/samifehri-%D8%A7%D9%84%D9%85%D9%86%D8%B5%D8%A9/id6473011937"
}

export const colors = {
    primary: "#007aff"
}


export const apiClient = axios.create({
    baseURL: config.v1Url,
    headers: {
        "Content-type": "application/json",
        "X-User-Agent": "tritux.sf"
    }
});

export const apiv2Client = axios.create({
    baseURL: config.v2Url,
    headers: {
        "Content-type": "application/json",
        "X-User-Agent": "tritux.sf"
    }
});

export const showSFToast = (message: string) => {
    toast.info(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        
    });
}
