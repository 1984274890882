import React from 'react'

interface ErrorPageProps {
    error?: string
}

function ErrorPage({ error }: ErrorPageProps) {
    return (
        <div style={{ minWidth: '100vw', minHeight: '100vh', color:'white',display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column' }}>
            <img src="/assets/images/error.png" alt="error" />
            Une erreur s'est produite
            <p>{error}</p>
        </div>
    )
}

export default ErrorPage