import { createStore } from "zustand";
import { SerieModel } from "../models/serie.model";
import { apiv2Client } from "../constants";
import { SFResponse } from "../models/sf-response.model";
import { UserModel } from "../models/user.model";
import { fetchRefresh, fetchUser } from "../fetchers/user-fetcher";



export interface AuthState {
    user?: UserModel;
    storeConnectedUser: (data: UserModel) => void;
    deleteConnectedUser: () => void;
    fetchConnectedUser: (token: string, onSuccess?: () => void) => Promise<UserModel | undefined>;
}

// define the state store
export const authStore = createStore<AuthState>((set): AuthState => ({
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined,
    storeConnectedUser: data => {
        localStorage.setItem('user', JSON.stringify(data))
        set({ user: data });
    },
    deleteConnectedUser: async () => {
        localStorage.removeItem('user')
        localStorage.removeItem("access_token")
        localStorage.removeItem("refreshToken")
        set({ user: undefined });
    },
    fetchConnectedUser: async (token, onSuccess?) => {
        //const token = localStorage.getItem('access_token') ?? "token";

        const user = await fetchUser(token)

        if (user && user.id) {
            //console.log("storing user", user)
            localStorage.setItem('user', JSON.stringify(user))
            set({ user: user });
            if (onSuccess) {
                onSuccess();
            }
            return user;
        }

        //console.log("user is undefined so refreshing the token")

        const refreshToken = localStorage.getItem('refreshToken') ?? "token";
        const refreshedToken = await fetchRefresh(refreshToken)
        if (!refreshedToken) {
            //console.log("refresh token is undefined so logout")
            localStorage.removeItem('user')
            localStorage.removeItem("access_token")
            localStorage.removeItem("refreshToken")
            set({ user: undefined });
            return undefined;
        }


        const refreshedUser = await fetchUser(refreshedToken)

        if (refreshedUser && refreshedUser.id) {
            //console.log("storing user after refresh", refreshedUser)
            localStorage.setItem('user', JSON.stringify(refreshedUser))
            set({ user: refreshedUser });
            if (onSuccess) {
                onSuccess();
            }
            return refreshedUser;
        }

        return undefined;



    },
}));


