import { Link } from 'react-router-dom'
import './footer.css'
import { useStore } from 'zustand'
import { authStore } from '../stores/auth-store'

function Footer() {
    const { user } = useStore(authStore)
    return (
        <>
            <footer className="footer-area bg-black-2 section-padding-lr">

                <div className="footer-bottom footer-bottom-ptb footer-black-hm4">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }} className="copyright">
                                    <img style={{ width: '60px' }} src="/assets/images/logo/sfd-blanc.png" alt="logo" />
                                    <p className="copyright-text mt-2">
                                        Copyright ©2024 Tous droits réservés
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <ul className="pt-[2px] mainmenu flex flex-col text-white lg:text-left text-center">
                                    <li className="group inline-block relative lg:mr-9 text-[14px] font-normal">
                                        <Link to="/legal-notice">Mentions légales</Link>
                                    </li>
                                    <li className="group inline-block relative mr-6 lg:mr-9 text-[14px] font-normal">
                                        <Link to="/terms-of-use">Condition d'utilisation</Link>
                                    </li>
                                    <li className="group inline-block relative lg:mr-9 text-[14px] font-normal">
                                        <a href="/privacy-policy/">Politique de confidentialité</a>
                                    </li>
                                </ul>
                            </div>

                            <div style={{ textAlign: 'center' }} className="col-md-4">
                                {!user && <p className="m-0">Vous avez déjà un compte ? <Link to="/login" className="link-auth">S’authentifier</Link></p>}
                                {user && user.hasSub === false && <Link to="/pricing" className="subscribe-btn my-2">S'abonner maintenant</Link>}
                                <p className="m-0">Pour toute réclamation, merci d'utiliser ce <a href="https://support.samifehri.tn/" target="_blank" className="link-auth">lien</a></p>
                            </div>


                        </div>
                    </div>
                </div>
            </footer>

        </>

    )
}

export default Footer