import React from 'react'
import { useStore } from 'zustand'
import { authStore } from '../../stores/auth-store'
import { constants } from 'buffer'
import { colors } from '../../constants'
import "./index.css"

import './plans.css'
import ProfileSection from './profile-section'
import SubscriptionSection from './subscription-section'

function Profile() {
    const { user } = useStore(authStore)
    return (
        <main className="my-account-wrapper section-pt-90 section-pb-90 bg-black sf-header-padding sf-min-height">
            <div className="container-fluid">
                <div className="col-md-12 col-lg-12">
                    {/* Nav tabs */}
                    <ul role="tablist" className="nav dashboard-list white mb--60">
                        <li className="active" role="presentation">
                            <a href="#account-details" data-bs-toggle="tab" className="tablist-btn active fw-bold">Détails du profil</a>
                        </li>
                        <li role="presentation">
                            <a href="#payment-info" data-bs-toggle="tab" className="tablist-btn fw-bold"> Informations de paiement</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content dashboard-content">
                    <ProfileSection />
                    <SubscriptionSection />
                </div>

            </div>
        </main>
    )
}

export default Profile