import { Navigate } from "react-router-dom";
import { UserModel } from "../models/user.model";

interface ProtectedAuthRouteProps {
    user?: UserModel
    children: JSX.Element
}

const ProtectedAuthRoute = ({ user, children }: ProtectedAuthRouteProps) => {
    if (!user) {
        return <Navigate to="/login" replace />;
    }
    return children;
};

export default ProtectedAuthRoute