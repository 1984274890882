import { createStore } from "zustand";
import { SerieModel } from "../models/serie.model";
import { apiv2Client } from "../constants";
import { SFResponse } from "../models/sf-response.model";
import { UserModel } from "../models/user.model";
import { SearchResponse } from "../models/search-response.model";



export interface SearchState {
    result: SearchResponse;
    fetchSearch: (searchQuery: string) => Promise<SearchResponse>;
}

// define the state store
export const searchStore = createStore<SearchState>((set): SearchState => ({
    result: { success: true, casts: [], episodes: [], series: [] },
    fetchSearch: async (searchQuery) => {
        try {
            const { data } = await apiv2Client.get<SearchResponse>(`/search?q=${searchQuery}`, {
            });
            set({ result: data });
            return data

        } catch (error: any) {
            //console.log("error in fetching search", error)
            return { success: false, casts: [], episodes: [], series: [] };
        }
    },
}));


