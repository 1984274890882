//import reactLogo from './assets/react.svg'
//import viteLogo from '/vite.svg'
import { Suspense, lazy, useEffect } from "react";


import Footer from "./layouts/footer";
import Header from "./layouts/header/";
import { QueryClient, QueryClientProvider } from 'react-query';

import { BrowserRouter, Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom"
import Profile from "./pages/profile";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from "zustand";
import { authStore } from "./stores/auth-store";
import ProtectedAuthRoute from "./utils/protected-auth-route";

import $ from "jquery";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./utils/common.css"
import { ScrollToTop } from "./utils/scroll-to-top";
import LoadingPage from "./utils/loading-page";


const Home = lazy(() => import("./pages/home/home"));
const Login = lazy(() => import("./pages/auth/login"));
const Register = lazy(() => import("./pages/auth/register"));

const Pricing = lazy(() => import("./pages/pricing/pricing"));
const SerieDetails = lazy(() => import("./pages/serie-details/serie-details"));
const Watchlist = lazy(() => import("./pages/playlist"));
const Search = lazy(() => import("./pages/search/"));
const Watch = lazy(() => import("./pages/watch/watch"));
const ResetPassword = lazy(() => import("./pages/reset-password/reset-password"));
const ResetCallback = lazy(() => import("./pages/reset-password/reset-callback"));

const RegisterCallBack = lazy(() => import("./pages/auth/register-callback"));
const RegisterMobileCallBack = lazy(() => import("./pages/auth/register-mobile-callback"));

const PaymentCallback = lazy(() => import("./pages/payment-callback/payment_callback"));
const LegalNotice = lazy(() => import("./pages/legal-notice"));
const TermsOfUse = lazy(() => import("./pages/terms-of-use"));

const Offre = lazy(() => import("./pages/20-mars"));


const queryClient = new QueryClient();




function App() {
  const { fetchConnectedUser, user } = useStore(authStore)

  useEffect(() => {
    initApp()
  }, [])

  const initApp = async () => {
    //console.log("fetch user in init app")
    const token = localStorage.getItem('access_token')
    if (token) {
      await fetchConnectedUser(token)
    }
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <QueryClientProvider client={queryClient}>
        <div className="main-wrapper">

          <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={user ? <Navigate to={'/'} /> : <Login />} />
              <Route path="/register" element={user ? <Navigate to={'/'} /> : <Register />} />
              <Route path="/verify_mail/:params" element={<RegisterCallBack />} />
              <Route path="/verify_mail/mobile/:params" element={<RegisterMobileCallBack />} />

              <Route path="/reset-password" element={user ? <Navigate to={'/'} /> : <ResetPassword />} />
              <Route path="/reset/:token" element={user ? <Navigate to={'/'} /> : <ResetCallback />} />

              <Route path="/profile" element={<ProtectedAuthRoute user={user} ><Profile /></ProtectedAuthRoute>} />
              <Route path="/series/:id" element={<SerieDetails />} />
              <Route path="/pricing" element={user && !user.hasSub ? <Pricing /> : <Navigate to={'/'} />} />
              <Route path="/watchlist" element={<Watchlist />} />
              <Route path="/search" element={<Search />} />
              <Route path="/watch/:serielink/:episodeId" element={<ProtectedAuthRoute user={user} ><Watch /></ProtectedAuthRoute>} />
              <Route path="/payment/:pm_reference/" element={<PaymentCallback />} />
              
              
              <Route path="/legal-notice" element={<LegalNotice />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/promo/20-mars" element={<Offre />} />

              <Route path="*" element={<Navigate to={'/'} />} />

            </Routes>
            <Footer />
          </BrowserRouter>

          <ToastContainer />
        </div>
      </QueryClientProvider>
    </Suspense>


  )
}

export default App
