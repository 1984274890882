import React from 'react'
import { colors } from '../../constants'
import { useQuery } from 'react-query'
import { useStore } from 'zustand'
import { authStore } from '../../stores/auth-store'
import { fetchCurrentSubscription } from '../../fetchers/pgw-fetcher'
import ErrorPage from '../../utils/error-page'
import LoadingPage from '../../utils/loading-page'
import OrderItem from './order-item'
import "./subscription-section.css"
import { Link, useNavigate } from 'react-router-dom'

function SubscriptionSection() {
    const { user } = useStore(authStore);
    const navigate = useNavigate();

    const { isLoading, error, data } = useQuery(["sfSubscription", user?.id], fetchCurrentSubscription)
    if (isLoading) return <LoadingPage />;
    if (error) return <div>An error occurred</div>;
    if (data?.success == false) return <ErrorPage />;
    //if (data?.hasSub == false) return <div className="tab-pane sf-min-height" id="payment-info"></div>

    const subscription = data?.result?.subscription;

    const price = data?.result?.price;
    const amount = price?.amount;
    const digitsAfterSeparator = price?.currency.digits_after_separator;
    const divider = Math.pow(10, digitsAfterSeparator ?? 3);
    const result = amount ? amount / divider : 0;
    const currencyCode = price?.currency.code;

    const isRenouvelable = data?.result?.product.code != "onetime_month";
    const subscriptionActive = subscription?.status === 0;


    return (
        <div className="tab-pane" id="payment-info">
            <div className="row">
                <div className="col-lg-12">

                    {subscription ? <div className="current-plan-wrapper white">
                        <div className="section-title-plan mb--40">
                            <h3>Votre abonnement actuel</h3>
                        </div>
                        {/* <div className="cards__inner1">
                            <div className="cards__card card">
                                <img src={data?.result!.product.image} alt=""  />
                                <h2 className="card__heading">{data?.result!.product.name}</h2>
                                <p className="card__price">{result} {currencyCode}</p>
                                <ul role="list" className="">
                                     <li>Date d'activation : {new Date(subscription.created_at).toLocaleDateString("fr-FR")}</li>
                                    <li >Date d'expiration : {new Date(subscription.end_date).toLocaleDateString("fr-FR")}</li> 
                                </ul>
                            </div>
                        </div> */}
                        <div className="cards__inner1">
                            <div className="cards__card card d-flex flex-row align-items-center">
                                <div>
                                    <img src={data?.result!.product.image} alt="" />
                                </div>
                                <div>
                                    <h2 className="card__heading mb-1">{data?.result!.product.name}</h2>
                                    {isRenouvelable
                                        ? subscriptionActive
                                            ? <p style={{ lineHeight: "normal" }} className='mb-0 text-success'>Abonnement actif</p>
                                            : <p style={{ lineHeight: "normal" }} className='mb-0 text-danger'>Suspendu. veuillez recharger {data?.result?.metadata['msisdn'] ? `votre numéro ${data?.result?.metadata['msisdn']}` : "votre numéro"}</p>
                                        : <p className='mb-0'>Valable jusqu'au {new Date(subscription.end_date).toLocaleDateString("fr-FR")}</p>}
                                </div>

                            </div>
                        </div>
                    </div>
                        :
                        <div onClick={() => navigate('/pricing')} className="current-plan-wrapper white">
                            <div className="section-title-plan mb--40">
                                <h3>Abonnez-vous</h3>
                            </div>
                            <div className="cards__inner1">
                                <div className="cards__card card d-flex flex-row align-items-center">
                                    <div>
                                        <img src="https://1825712079.rsc.cdn77.org/images/abonnement-diamond.png	"></img>
                                    </div>
                                    <div>
                                        <h2 className="card__heading">Abonnez-vous</h2>
                                        <p >Choisissez un de nos abonnements</p>
                                    </div>

                                </div>
                            </div>
                        </div>}


                    <div className='current-plan-wrapper white' style={{ marginTop: 60 }}>
                        <div className="section-title-plan mb--40">
                            <h3>Historique des paiements</h3>
                        </div>


                        <div className="card-table">
                            <div className="table-responsive text-center">
                                <table className="table text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-[14px] font-semibold text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400"><tr data-v-62fd22ef="">
                                        <th scope="col" className="px-6 py-3 text-white">
                                            #
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-white">
                                            Date
                                        </th> <th scope="col" className="px-6 py-3 text-white">
                                            Montant
                                        </th> <th scope="col" className="px-6 py-3 text-white">
                                            Opération
                                        </th></tr>
                                    </thead>
                                    <tbody>
                                        {data?.result?.orders.slice(0, 10).map((order, index) =>
                                            <OrderItem key={index} index={index} order={order} />
                                        )}
                                    </tbody>
                                </table>
                                {data?.hasSub === false
                                    && <div style={{ textAlign: "center" }} className="nodata">
                                        <div>Vous n'avez pas encore d'historique d'abonnement</div>
                                    </div>
                                }
                                {data?.result?.orders?.length === 0
                                    && <div style={{ textAlign: "center" }} className="nodata">
                                        <div>Vous n'avez pas encore d'historique d'abonnement</div>
                                    </div>}
                            </div>
                        </div>

                    </div>






                </div>
            </div>
        </div>
    )
}

export default SubscriptionSection