

import { apiv2Client } from "../constants";
import { PGWResponse } from "../models/pgw-response.model";
import { UserModel } from "../models/user.model";

export const fetchUser = async (token: string): Promise<UserModel | undefined> => {
    try {
        const response = await apiv2Client.get<UserModel>(`/me`, {
            headers: { "Authorization": token }
        });
        return response.data

    } catch (error: any) {
        //console.log("error in fetching connected user", error)
        return undefined;
    }

}

export const fetchRefresh = async (token: string): Promise<string|undefined> => {
    try {
        const response = await apiv2Client.post(`/auth/refresh-token`, {
            refreshToken: token
        });
        if (response.data.success) {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
        }
        return response.data.access_token

    } catch (error: any) {
        //console.log("error in fetching refresh token", error)
        return undefined;
    }

}