import React, { useState } from 'react'
import { authStore } from '../../stores/auth-store'
import { useStore } from 'zustand'
import "./profile-section.css"
import { apiClient, showSFToast } from '../../constants'
import { SFResponse } from '../../models/sf-response.model'
import SfLoadingSpinner from '../../utils/sf-loading-spinner'

function ProfileSection() {
    const { user, storeConnectedUser } = useStore(authStore)


    const [firstname, setFirstname] = useState(user?.first_name)
    const [lastname, setLastname] = useState(user?.last_name)
    const [phone, setPhone] = useState<string>(user?.phone_number?.toString() ?? "")
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (phone && (phone.length < 8)) {
            return showSFToast("Votre numéro de téléphone est incorrect")
        }

        if (phone && phone.length > 13) {
            return showSFToast("Votre numéro de téléphone ne doit pas dépasser 12 chiffres")
        }
        setLoading(true)
        const token = localStorage.getItem('access_token') ?? 'token';
        const { data } = await apiClient.put<SFResponse<any>>("/user", {
            "first_name": firstname,
            "last_name": lastname,
            "phone_number": phone,
        }, { headers: { "Authorization": token } });
        setLoading(false)
        //console.log("update user response ", data)
        if (data.success) {
            storeConnectedUser({ ...user!, first_name: firstname!, last_name: lastname!, phone_number: Number(phone) })
            showSFToast('Vos informations ont été modifiées avec succès')
        }


    }

    return (
        <div className="tab-pane active" id="account-details">
            <div className="row">
                <div className="col-lg-9 col-xl-10 col-md-9">
                    <div className="account-form-container-wrapper">
                        <div className="login">
                            <div className="account-form-container">
                                <div className="account-login-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="my-account-form-wrap border-bottom-2 white pb--30">
                                            <h3 className="fw-normal">Informations Générales</h3>
                                            <p>En connaissant vos Informations Générales, nous pouvons rendre notre expérience d'assistance beaucoup plus personnelle.</p>
                                            <div className="row account-input-box">
                                                <div className="col-md-6 single-input-box">
                                                    <label>E-mail</label>
                                                    <input type="text" name="email" value={user?.email} />
                                                </div>
                                                <div className="col-md-6 single-input-box">
                                                    <label>Prénom</label>
                                                    <input required type="text" name="first-name" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                                </div>
                                                <div className="col-md-6 single-input-box">
                                                    <label>Nom</label>
                                                    <input required type="text" name="last-name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                                </div>
                                                <div className="col-md-6 single-input-box">
                                                    <label>Numéro de téléphone</label>
                                                    <input required type="number" value={(phone != "11111111" ? phone : "")} onChange={(e) => setPhone(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="button-box mt--30">
                                            <button disabled={loading} type='submit' className="btn theme-color-four d-flex align-items-center">
                                                {loading && <>
                                                    <SfLoadingSpinner color='white' />
                                                    &nbsp;
                                                </>}
                                                Modifier
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileSection