import React from 'react'
import SfLoadingSpinner from './sf-loading-spinner'



function LoadingPage() {
    return (
        <div style={{ width: '100vw', height: '100vh', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <SfLoadingSpinner size={50} color='white' />
        </div>
    )
}

export default LoadingPage