

import { apiv2Client } from "../constants";
import { PGWCreateIntentResponse } from "../models/pgw-create-intent-response";
import { PGWResponse } from "../models/pgw-response.model";
import { PGWSubscriptionResponse } from "../models/pgw-subscription-response";

export const fetchProducts = async (): Promise<PGWResponse> => {
    try {
        const response = await apiv2Client.get<PGWResponse>(`/pgw/products`, {
        });
        return response.data

    } catch (error: any) {
        //console.log("error in fetching products", error)
        return { status: "error" };
    }
}

export const fetchCurrentSubscription = async (): Promise<PGWSubscriptionResponse> => {
    const token = localStorage.getItem('access_token') ?? 'token';
    try {
        const response = await apiv2Client.get<PGWSubscriptionResponse>(`/pgw/getSubscription`, {
            headers: { "Authorization": token }
        });
        response.data.result?.orders.reverse();
        return response.data

    } catch (error: any) {
        //console.log("error in fetching current sub", error)
        return { success: false, hasSub: false };
    }
}

export const createPaymentIntent = async (paymeentMethod: string, price: string): Promise<PGWCreateIntentResponse> => {
    const token = localStorage.getItem('access_token') ?? 'token';
    try {
        const response = await apiv2Client.post<PGWCreateIntentResponse>(`/pgw/createIntent`, {
            paymentmethod: paymeentMethod,
            price: price,
            web: true
        }, { headers: { "Authorization": token } });
        return response.data

    } catch (error: any) {
        //console.log("error in fetching createPaymentIntent", error)
        return { status: 'error' };
    }
}

