import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useStore } from "zustand";
import { authStore } from "../../stores/auth-store";
import { searchStore } from "../../stores/search-store";
import './index.css'
function Header() {
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [mobileMenu, setMobileMenu] = useState(false)

    const navigate = useNavigate();
    const { user, deleteConnectedUser } = useStore(authStore);
    const { fetchSearch } = useStore(searchStore);

    const toggleSearch = () => {
        setIsSearchOpen((prev) => !prev);
    }

    const handleSearch = async (e: any) => {
        e.preventDefault();
        if (!searchQuery) {
            return
        }
        navigate(`/search?q=${searchQuery}`)
        await fetchSearch(searchQuery)
    }

    const handleLogout = () => {
        deleteConnectedUser()
        navigate("/login")
    }

    const handleNavigate = (link: string) => {
        setIsSearchOpen(false)
        setMobileMenu(false)
        navigate(link)
    }
    return (
        <header className="header-area bg-black section-padding-lr sf-header" >
            <div className="container-fluid">
                <div className="header-wrap header-netflix-style">
                    <div className="logo-menu-wrap">
                        {/* Logo */}
                        <div className="logo">
                            <a onClick={() => handleNavigate('/')}><img width={55} src="/assets/images/logo/logo.png" alt="" /></a>
                        </div>
                        {/* Logo */}
                        <div className="main-menu main-theme-color-four">
                            <nav className="main-navigation d-lg-block d-none">
                                <ul>
                                    <li className="active"><a onClick={() => handleNavigate('/')}>Accueil</a></li>
                                    {/* <li className="active"><a onClick={() => handleNavigate('/watchlist')}>Drame</a></li>
                                    <li className="active"><a onClick={() => handleNavigate('/watchlist')}>Documentaire</a></li> */}
                                    <li className="active"><a onClick={() => handleNavigate('/watchlist')}>Liste de lecture</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="right-side d-flex">
                        <div className="header-search-2 me-2">
                            <a onClick={toggleSearch} className={isSearchOpen ? "search-toggle" : "search-toggle"}>
                                {!isSearchOpen
                                    ? <i className="zmdi zmdi-search"></i>
                                    : <i className="zmdi zmdi-close"></i>}
                            </a>
                            <div className={isSearchOpen ? "search-wrap-2 search-open" : "search-wrap-2"}>
                                <form onSubmit={handleSearch}>
                                    <input onChange={(event) => setSearchQuery(event.target.value)} placeholder="Recherche" type="text" />
                                    <button className="button-search"><i className="zmdi zmdi-search"></i></button>
                                </form>
                            </div>
                        </div>
                        {user && user.hasSub == false && <div className="subscribe-btn-wrap">
                            <a onClick={() => handleNavigate('/pricing')} className="subscribe-btn">S'abonner</a>
                        </div>}

                        {user
                            ? <div className="our-profile-area ">
                                <a type="button" className="our-profile-pc" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="profile-img">
                                        {user.first_name.substring(0, 1).toUpperCase()}{user.last_name.substring(0, 1).toUpperCase()}
                                    </div>
                                </a>
                                <div className="dropdown-menu netflix-profile-style red">
                                    <ul>
                                        <li className="single-list"><a onClick={() => handleNavigate('/profile')} >Mon profil</a></li>
                                        <li className="single-list">
                                            <a style={{ fontSize: "13px" }} onClick={() => {
                                                setIsSearchOpen(false)
                                                handleLogout()
                                            }}>Se déconnecter</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            : <a onClick={() => handleNavigate('/login')} className="subscribe-btn">S'authentifier</a>}


                        {/* mobile-menu start */}

                        <div className="mobile-menu d-block d-lg-none mean-container">
                            <div className="mean-bar">
                                <a onClick={() => setMobileMenu(!mobileMenu)} type="button" className="meanmenu-reveal" >
                                    {mobileMenu
                                        ? <span className="menu-close"></span>
                                        : <span className="menu-bar"></span>}
                                </a>
                                {mobileMenu && <nav className={isSearchOpen ? "mean-nav top-mean-nav" : "mean-nav"}>
                                    <ul>
                                        <li className="active"><a onClick={() => handleNavigate('/')}>Accueil</a></li>
                                        <li className="active"><a onClick={() => handleNavigate('/watchlist')}>Liste de lecture</a></li>
                                    </ul>
                                </nav>}
                            </div>
                        </div>

                        {/* mobile-menu end */}
                    </div>
                </div>
            </div>
        </header >
    )
}

export default Header