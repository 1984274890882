import React from 'react'

interface OrderItemProps {
    order: any
    index: number
}
function OrderItem({ order, index }: OrderItemProps) {
    const amount = order.amount;
    const digitsAfterSeparator = 3;
    const divider = Math.pow(10, digitsAfterSeparator);
    const result = amount / divider;
    const currencyCode = "TND";

    return (
        <tr className="border-b dark:bg-gray-800 dark:border-gray-700">
            <td className="px-6 py-4 text-white">
                {index + 1}
            </td>
            <td className="px-6 py-4 text-white">
                {new Date(order.created_at).toLocaleDateString("fr-FR")}
            </td>
            <td className="px-6 py-4 text-white">
                {result} {currencyCode}
            </td> <td className="px-6 py-4 text-white">
                {index === 0
                    ? "Activation d'abonnement"
                    : "Renouvelement d'abonnement"}
            </td>
        </tr>
    )
}

export default OrderItem